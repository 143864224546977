'use client';

import { useState } from 'react';
import { Button } from '@/components/ui/button';
import { Card } from "@/components/ui/cards";
import { Loader2, ShoppingCart } from 'lucide-react';
import { Product, Store } from '@/utils/interfaces/interfaces';

interface ProductCardProps {
  ID: string;
  children: React.ReactNode;
  className?: string;
  settings?: Partial<Store>;
}

export function ProductCard({ ID, children, className, settings }: ProductCardProps) {
  const [loading, setLoading] = useState(false);

  const addToCart = async () => {
    try {
      setLoading(true);
      const response = await fetch('/api/cart/add', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          productId: ID,
          quantity: 1,
        }),
      });

      if (!response.ok) throw new Error('Failed to add to cart');
      // Add success notification here
    } catch (error) {
      // Add error notification here
      console.error('Error adding to cart:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Card 
      className={`${className} ${
        settings?.background_color ? `bg-[${settings.background_color}]` : ''
      } ${
        settings?.font_color ? `text-[${settings.font_color}]` : ''
      }`}
    >
      {children}
      <div className="p-6 mt-auto">
        <Button
          onClick={addToCart}
          disabled={loading}
          className={`w-full ${
            settings?.primary_color 
              ? `bg-[${settings.primary_color}] hover:bg-[${settings.primary_color}]/90
                 ${loading ? 'opacity-50 cursor-not-allowed' : ''}`
              : ''
          }`}
        >
          {loading ? (
            <Loader2 
              className={`h-4 w-4 animate-spin ${
                settings?.secondary_color ? `text-[${settings.secondary_color}]` : ''
              }`}
            />
          ) : (
            <>
              <ShoppingCart 
                className={`mr-2 h-4 w-4 ${
                  settings?.secondary_color ? `text-[${settings.secondary_color}]` : ''
                }`}
              />
              <span className={
                settings?.secondary_color ? `text-[${settings.secondary_color}]` : ''
              }>
                Add to Cart
              </span>
            </>
          )}
        </Button>
      </div>
    </Card>
  );
}