'use client';

import { useState, useEffect } from 'react';
import { Button } from '@/components/ui/button';
import { ProductCard } from './ProductCard';
import { Product, Store } from '@/utils/interfaces/interfaces';
import { Loader2, ArrowRight } from 'lucide-react';
import Link from 'next/link';
import { createClient } from '@/utils/supabase/client';

interface ProductCarouselProps {
  title?: string;
  subtitle?: string;
  domain: string;
  settings?: Partial<Store>;
}

export default function ProductCarousel({ 
  title = "Featured Products",
  subtitle = "Check out our latest products",
  domain,
  settings
}: ProductCarouselProps) {
  console.log('🎠 ProductCarousel Render:', { 
    title, 
    subtitle, 
    domain, 
    settings,
    ProductCardExists: !!ProductCard
  });
  
  const [products, setProducts] = useState<Product[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [isClient, setIsClient] = useState(false);

  useEffect(() => {
    console.log('🎠 Client-side effect triggered');
    setIsClient(true);
  }, []);

  useEffect(() => {
    const fetchProducts = async () => {
      console.log('🎠 Fetching products started:', { isClient, domain });
      try {
        const supabase = createClient();
        const trimmedDomain = domain.trim();
        
        if (!trimmedDomain) {
          console.log('🎠 No domain provided, skipping fetch');
          return;
        }
        
        console.log('🎠 Fetching store for domain:', trimmedDomain);
        const { data: store, error: storeError } = await supabase
          .from('stores')
          .select('id')
          .eq('domain', trimmedDomain)
          .single();

        console.log('🎠 Store query result:', { store, storeError });

        if (storeError) throw new Error('Failed to fetch store');

        console.log('🎠 Fetching products for store:', store.id);
        const { data, error } = await supabase
          .from('products')
          .select('*')
          .eq('store_id', store.id)
          .limit(4)
          .order('created_at', { ascending: false });

        console.log('🎠 Products query result:', { 
          productsCount: data?.length,
          hasError: !!error 
        });

        if (error) throw new Error('Failed to fetch products');
        
        setProducts(data);
      } catch (err) {
        console.error('🎠 Error fetching products:', err);
        setError('Failed to load products');
      } finally {
        setLoading(false);
        console.log('🎠 Fetch complete, loading set to false');
      }
    };

    if (isClient && domain) {
      fetchProducts();
    }
  }, [domain, isClient]);

  console.log('🎠 Component state:', { 
    isClient, 
    loading, 
    error, 
    productsCount: products.length,
    settings: {
      primary_color: settings?.primary_color,
      tertiary_color: settings?.tertiary_color,
      background_color: settings?.background_color,
      font_color: settings?.font_color
    }
  });

  console.log('🎠 Loading check:', {
    isClient,
    loading,
    shouldShowLoader: !isClient || loading,
    primaryColor: settings?.primary_color,
    Loader2Exists: !!Loader2
  });

  if (!isClient || loading) {
    try {
      return (
        <div className="min-h-[400px] flex items-center justify-center">
          {Loader2 ? (
            <Loader2 
              style={{ color: settings?.primary_color }}
              className="h-8 w-8 animate-spin"
            />
          ) : (
            <div>Loading...</div>
          )}
        </div>
      );
    } catch (error) {
      console.error('🎠 Loader error:', error);
      return (
        <div className="min-h-[400px] flex items-center justify-center">
          Loading...
        </div>
      );
    }
  }

  if (error) {
    return (
      <div className="p-4 text-red-500 text-center min-h-[400px] flex items-center justify-center">
        {error}
      </div>
    );
  }

  if (products.length === 0) {
    return (
      <div 
        style={{ color: settings?.tertiary_color }}
        className="min-h-[400px] flex items-center justify-center"
      >
        No products available
      </div>
    );
  }

  return (
    <section style={{ backgroundColor: settings?.background_color }} className="py-12">
      <div className="container mx-auto px-4">
        <div className="flex justify-between items-end mb-8">
          <div>
            <h2 
              style={{ color: settings?.font_color }}
              className="text-2xl font-bold mb-2"
            >
              {title}
            </h2>
            <p style={{ color: settings?.tertiary_color }}>
              {subtitle}
            </p>
          </div>
          <Button 
            asChild 
            variant="ghost" 
            style={{ 
              color: settings?.font_color,
              '--hover-color': `${settings?.font_color}90`
            } as React.CSSProperties}
            className="group hover:text-[var(--hover-color)]"
          >
            <Link href={`/domain/${domain}/products`}>
              View All
              <ArrowRight className="ml-2 h-4 w-4 transition-transform group-hover:translate-x-1" />
            </Link>
          </Button>
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6">
          {products.map((product) => {
            console.log('🎠 Rendering ProductCard:', { 
              productId: product.id,
              ProductCardComponent: !!ProductCard
            });
            return (
              <ProductCard 
                key={product.id} 
                ID={product.id}
                className="h-full"
                settings={settings}
              >
                <Link 
                  href={`/domain/${domain}/products/view/${product.id}`}
                  className="block p-4"
                >
                  {product.image_urls?.[0] && (
                    <div className="aspect-square mb-4">
                      <img
                        src={product.image_urls[0]}
                        alt={product.name}
                        className="w-full h-full object-cover rounded-lg"
                      />
                    </div>
                  )}
                  <h3 
                    style={{ color: settings?.font_color }}
                    className="font-semibold mb-2"
                  >
                    {product.name}
                  </h3>
                  <div className="flex items-center gap-2">
                    <span 
                      style={{ color: settings?.primary_color }}
                      className="text-lg font-bold"
                    >
                      ${product.price.toFixed(2)}
                    </span>
                  </div>
                </Link>
              </ProductCard>
            );
          })}
        </div>
      </div>
    </section>
  );
}
