import { TeamProps, TeamMember } from "@/utils/interfaces/interfaces";

export default function Team({ teamTitle, teamDescription, teamMembers, settings }: TeamProps) {
  return (
    <section style={{ backgroundColor: settings?.background_color }} className="py-16">
      <div className="container mx-auto px-4">
        <div className="text-center mb-12">
          <h2 
            style={{ color: settings?.font_color }}
            className="text-3xl font-bold mb-4"
          >
            {teamTitle}
          </h2>
          <p 
            style={{ color: settings?.tertiary_color }}
            className="max-w-2xl mx-auto"
          >
            {teamDescription}
          </p>
        </div>
        
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {teamMembers.map((member, index) => (
            <div 
              key={index} 
              style={{ backgroundColor: settings?.secondary_color }}
              className="rounded-lg shadow-md overflow-hidden"
            >
              {member.image_url && (
                <img
                  src={member.image_url}
                  alt={member.name}
                  className="w-full h-64 object-cover"
                />
              )}
              <div className="p-6">
                <h3 
                  style={{ color: settings?.font_color }}
                  className="font-bold text-xl mb-1"
                >
                  {member.name}
                </h3>
                <p style={{ color: settings?.primary_color }}>
                  {member.role}
                </p>
                <p style={{ color: settings?.tertiary_color }}>
                  {member.bio}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}
