// utils/ses.ts
import { SESClient, SendEmailCommand } from "@aws-sdk/client-ses";

// Configure AWS SES
const sesClient = new SESClient({
  region: process.env.AWS_REGION || "us-east-1",
  credentials: {
    accessKeyId: process.env.AWS_ACCESS_KEY_ID || "",
    secretAccessKey: process.env.AWS_SECRET_ACCESS_KEY || "",
  },
});

interface EmailParams {
  to: string | string[];
  subject: string;
  body: string;
  from?: string;
}

export async function sendEmail({ to, subject, body, from }: EmailParams) {
  // Make sure we have a valid sender email
  const sourceEmail = from || process.env.AWS_SES_FROM_EMAIL;
  if (!sourceEmail) {
    throw new Error("Sender email address is required");
  }

  console.log('Sending email with source:', sourceEmail); // Debug log

  const command = new SendEmailCommand({
    Source: sourceEmail,
    Destination: {
      ToAddresses: Array.isArray(to) ? to : [to],
    },
    Message: {
      Subject: {
        Data: subject || "No Subject",
        Charset: 'UTF-8'
      },
      Body: {
        Text: {
          Data: body,
          Charset: 'UTF-8'
        }
      }
    }
  });

  try {
    const response = await sesClient.send(command);
    return {
      success: true,
      messageId: response.MessageId,
    };
  } catch (error) {
    console.error("Error sending email:", error);
    return {
      success: false,
      error: error instanceof Error ? error.message : "Unknown error occurred",
    };
  }
}

// Example API route implementation
// pages/api/send-email.ts