import { StatsProps, Store } from "@/utils/interfaces/interfaces";

export default function Stats({ stats, settings }: StatsProps) {
  if (!stats || stats.length === 0) return null;

  return (
    <section style={{ backgroundColor: settings?.background_color }} className="py-16">
      <div className="container mx-auto px-4">
        <div className="grid grid-cols-2 md:grid-cols-4 gap-8">
          {stats.map((stat, index) => (
            <div key={index} className="text-center">
              <div 
                style={{ color: settings?.primary_color }}
                className="text-4xl font-bold mb-2"
              >
                {stat.value}
              </div>
              <div 
                style={{ color: settings?.font_color }}
                className="text-sm"
              >
                {stat.label}
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}
